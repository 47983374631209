import { css } from "glamor";
import React, { useCallback, useEffect } from "react";
import { Button, colors, spacing } from "../library";
import { Form, PhoneField, TextField } from "../library/forms";
import EmailField from "../library/forms/EmailField";
import { change } from "redux-form";

const titleStyle = {
  fontWeight: 700,
  letterSpacing: 0.8,
};
const mainTitleClass = css(titleStyle, {
  fontSize: "41px",
  color: colors.black,
  "@media(max-width: 575px)": {
    paddingTop: spacing.m,
  },
});
const subTitle = css({
  color: colors.greyDarker,
  marginBottom: spacing.l,
});
const fieldsClass = css({
  marginTop: "auto",
  width: "100%",
});
const submitButtonStyle = {
  marginTop: spacing.l,
};
const formStyle = {
  padding: spacing.l,
};
const countryCodeProps = {
  upward: true,
};
const holderNameValidationMessage = {
  minWords: "* Nome completo",
};

export default function CheckoutLeadForm({
  dispatch,
  handleSubmit,
  error,
  invalid,
  submitting,
  email,
  emailConfirmation,
  countryCodeOptions,
  phone,
  phoneCountryCodeId,
}) {
  const skipActionHandler = useCallback((e) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    dispatch(change("payment", "customerData[email]", email));
    dispatch(change("payment", "customerData[phone]", phone));
    dispatch(change("payment", "customerData[phoneCountryCodeId]", phoneCountryCodeId));
  }, [dispatch, email, phone, phoneCountryCodeId]);

  return (
    <Form onSubmit={handleSubmit} error={error} style={formStyle}>
      <h1 className={mainTitleClass}>Comece agora!</h1>
      <h2 className={subTitle}>
        Junte-se a mais de 100 mil alunos e mude sua carreira de uma vez por
        todas.
      </h2>

      <div className={fieldsClass}>
        <TextField
          name="name"
          placeholder="Nome completo"
          validationMessages={holderNameValidationMessage}
          minWords={2}
          shortMessages
          fluid
          required
        />

        <EmailField name="email" placeholder="E-mail" required />

        <EmailField
          name="emailConfirmation"
          placeholder="Confirmação de E-mail"
          onPaste={skipActionHandler}
          onDrop={skipActionHandler}
          required
        />

        <PhoneField
          name="phone"
          countryCodeFieldName="phoneCountryCodeId"
          countryCodeOptions={countryCodeOptions}
          countryCodeProps={countryCodeProps}
          placeholder="Celular (Whatsapp)"
          fluid
          required
        />

        <Button
          fluid
          type="submit"
          color="black"
          icon="shopping cart"
          content="AVANÇAR"
          size="big"
          loading={submitting}
          style={submitButtonStyle}
          disabled={email !== emailConfirmation || invalid || submitting}
        />
      </div>
    </Form>
  );
}
