import { createSelector } from "reselect";

const getStudyGoals = (state) => state.entities.studyGoals;
const getProps = (_, props = {}) => props;

export const selectAllStudyGoals = createSelector(getStudyGoals, (studyGoals) =>
  Object.values(studyGoals)
);

export const selectStudyGoalByProps = createSelector(
  selectAllStudyGoals,
  getProps,
  (studyGoals, { coursesUserId }) =>
    studyGoals
      .filter((studyGoal) => studyGoal.coursesUserId === coursesUserId)
      .at(-1)
);
