import React, { useMemo } from "react";
import { connect } from "react-redux";
import { selectStudyGoalByProps } from "../../selectors/studyGoalSelectors";
import { getCurrentCourseUser } from "../../selectors/coursesUserSelectors";
import WeekdayStudyGoals from "./WeekdayStudyGoals";

function getWeekday(weekdayStudyGoals, weekday) {
  return weekdayStudyGoals.find(
    (weekdayStudyGoal) => weekdayStudyGoal.weekday == weekday
  );
}

function WeekdayStudyGoalsContainer(props) {
  const weekdayStudyGoals = props.studyGoal.weekdayStudyGoalsAttributes;

  const weekdays = useMemo(() => {
    const sunday = getWeekday(weekdayStudyGoals, "sunday");
    const monday = getWeekday(weekdayStudyGoals, "monday");
    const tuesday = getWeekday(weekdayStudyGoals, "tuesday");
    const wednesday = getWeekday(weekdayStudyGoals, "wednesday");
    const thursday = getWeekday(weekdayStudyGoals, "thursday");
    const friday = getWeekday(weekdayStudyGoals, "friday");
    const saturday = getWeekday(weekdayStudyGoals, "saturday");

    return {
      sunday,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
    };
  }, [weekdayStudyGoals]);

  return <WeekdayStudyGoals weekdays={weekdays} {...props} />;
}

function mapStateToProps(state) {
  const coursesUser = getCurrentCourseUser(state);
  const studyGoal = selectStudyGoalByProps(state, {
    coursesUserId: coursesUser.id,
  });

  return {
    studyGoal,
  };
}

export default connect(mapStateToProps)(WeekdayStudyGoalsContainer);
