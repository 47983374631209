import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import StudyGoal from "./StudyGoal";
import { selectStudyGoalByProps } from "../../selectors/studyGoalSelectors";
import { getCurrentCourseUser } from "../../selectors/coursesUserSelectors";
import { fetchStudyGoal } from "../../processes/studyGoalProcesses";
import { currentCourse } from "../../selectors/courseSelectors";

const getStudiedTimeSeconds = (studyGoal) =>
  studyGoal?.weekdayStudyGoalsAttributes
    ?.flatMap((weekdayStudyGoal) => weekdayStudyGoal.dayStudyGoalsAttributes)
    ?.flatMap((dayStudyGoal) => dayStudyGoal.dayStudyGoalsLessonAttributes)
    ?.map((dayStudyGoalsLesson) => dayStudyGoalsLesson.studiedTimeSeconds);

function StudyGoalContainer(props) {
  const { dispatch, studyGoal, coursesUser } = props;

  useEffect(() => {
    if (studyGoal?.id) {
      fetchStudyGoal(dispatch, studyGoal?.id);
    }
  }, [dispatch, studyGoal?.id]);

  const studyGoalStats = useMemo(() => {
    const studiedTimeSeconds = getStudiedTimeSeconds(studyGoal);
    const totalStudiedTimeSeconds = studiedTimeSeconds?.reduce(
      (a, b) => a + b,
      0
    );

    const studyGoalProgress =
      (totalStudiedTimeSeconds * 100) / studyGoal?.dayStudyDurationSeconds;

    return { totalStudiedTimeSeconds, studyGoalProgress };
  }, [studyGoal]);

  return coursesUser ? <StudyGoal {...studyGoalStats} {...props} /> : null;
}

const mapStateToProps = (state) => {
  const coursesUser = getCurrentCourseUser(state);
  const course = currentCourse(state);

  return {
    coursesUser,
    course,
    studyGoal: selectStudyGoalByProps(state, {
      coursesUserId: coursesUser?.id,
    }),
  };
};

export default connect(mapStateToProps)(StudyGoalContainer);
