import React from "react";
import { Select } from "semantic-ui-react";
import { css } from "glamor";
import { colors, spacing } from "../theme";

const container = css({
  display: "flex",
  alignItems: "center",
  border: `1px solid ${colors.greyLight}`,
  borderRadius: "10px !important",
});

const labelClass = css({
  padding: spacing.m,
  backgroundColor: `${colors.greyLighter} !important`,
  border: "none !important",
  borderRadius: "10px 0 0 10px",
  color: colors.black,
  fontWeight: 600,
  lineHeight: "10px",
});

const dropdownClass = css({
  display: "flex",
  justifyContent: "space-between",
  paddingRight: `${spacing.s} !important`,
  border: "none !important",
  width: "100%",
});

export default function DropDownInput(props) {
  const { innerlabel, className } = props;

  return (
    <div className={container}>
      {innerlabel && <label className={labelClass}>{innerlabel}</label>}
      <Select
        {...props}
        className={css(dropdownClass, className).toString()}
        icon="chevron down"
      />
    </div>
  );
}
