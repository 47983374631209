import { css } from "glamor";
import { colors, spacing, styles, typographyColors, uiColors } from "./theme";
const linkResetStyle = {
  textDecoration: "none",
  color: colors.primary,
};

export default function applyGlobalStyles() {
  css.global("body", {
    ...styles.defaultFontFamily,
    ...styles.bodyText,
  });
  css.global(`[class*="header"], [class*="item"], [class*="button"]`, {
    fontFamily: "Montserrat, sans-serif !important",
  });
  css.global("h1, h2, h3, h4, h5, h6", {
    ...styles.alternativeFontFamily,
    color: typographyColors.defaultTitle,
    letterSpacing: -0.5,
    fontWeight: "400",
    margin: 0,
    padding: 0,
  });
  css.global("h1, h2, h3, h4, h5, h6 + p", {
    marginBottom: spacing.xs,
  });
  css.global("p", {
    ...styles.defaultFontFamily,
    ...styles.bodyText,
    margin: 0,
    padding: 0,
  });
  css.global("p + p", {
    marginBottom: spacing.xs,
  });
  css.global("a", linkResetStyle);
  css.global("a:visited", linkResetStyle);
  css.global("a:focus", linkResetStyle);
  css.global("a:hover", linkResetStyle);
  css.global("a:active", linkResetStyle);

  // THEME OVERRIDES
  css.global(".ui.form input", {
    ...styles.defaultFontFamily,
    borderRadius: `${spacing.xs} !important`,
  });
  css.global("::placeholder", {
    color: `${colors.greyDark} !important`,
  });
  css.global(".ui.form input", {
    minHeight: spacing.xl,
    display: "flex",
    alignItems: "center",
    borderRadius: spacing.xs,
  });
  css.global(".ui.selection.dropdown", {
    minHeight: "50px",
    display: "flex",
    alignItems: "center",
    borderRadius: spacing.xs,
    background: "none",
    padding: 0,
    paddingLeft: spacing.s,
  });
  css.global(".ui.search.dropdown>.text", {
    textWrap: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  });
  css.global(".ui.fluid.dropdown>.dropdown.icon", {
    marginTop: "auto",
    marginBottom: "auto",
  });
  css.global(".ui.comments", {
    maxWidth: "100%",
  });
  css.global(".ui.comments .comment", {
    paddingBottom: spacing.m,
    marginBottom: spacing.m,
    borderBottom: `1px solid ${uiColors.border}`,
  });
  css.global(".ui.comments .comment .comments .comment", {
    paddingBottom: 0,
    marginBottom: 0,
    borderBottom: 0,
  });
  css.global(".ui.comments .comment .comments", {
    ...styles.roundedCorners,
    marginTop: spacing.s,
    backgroundColor: uiColors.contentCards,
    marginLeft: 0,
  });
  css.global(".ui.comments .comment .text", {
    ...styles.bodyText,
    ...styles.defaultFontFamily,
    paddingRight: spacing.s,
    marginBottom: 0,
  });
  css.global(".ui.form input[type=file]", {
    border: "none",
  });
  css.global(".ui.search>.ui.input, .ui.search>.ui.input > input", {
    borderRadius: 0,
    width: "100%",
  });
  css.global(".ui.search>.results", {
    left: "auto",
    right: 0,
  });
  css.global(".ui.search>.results .result", {
    padding: 0,
  });
  css.global(".ui.blue.progress .bar", {
    backgroundColor: colors.secondary,
  });
  css.global(".ui.card", {
    borderRadius: spacing.xs,
  });
  css.global(".ui.card>.content>.header", {
    fontFamily: "FamiliarPro, sans-serif !important",
  });
  css.global(".ui.form input[type=date]", { display: "block" });
  css.global(".ui.error.message", { whiteSpace: "pre-line" });

  // BUTTONS
  css.global(".button", {
    fontWeight: "400 !important",
  });
  css.global(".ui.primary.button", {
    backgroundColor: colors.primary,
    color: colors.greyClear,
  });
  css.global(".ui.buy.button", {
    backgroundColor: "#38CD58",
    color: colors.greyClear,
  });
  css.global(".ui.buy.button:hover", {
    backgroundColor: "#38CD58DD",
    cursor: "pointer",
  });
  css.global(".ui.outline.button", {
    backgroundColor: colors.white,
    border: `1px solid ${colors.primary} !important`,
    color: colors.primary,
  });
  css.global(".ui.outline.button:hover", {
    backgroundColor: colors.primary,
    border: `1px solid ${colors.primary} !important`,
    color: colors.white,
    cursor: "pointer",
  });
  css.global(".ui.page.dimmer", {
    padding: 0,
  });
  css.global(".ui.primary.button:hover, .ui.primary.button:focus", {
    backgroundColor: colors.primaryDarker,
    cursor: "pointer",
  });
  css.global(".ui.secondary.button", {
    backgroundColor: colors.primaryLighter,
    color: colors.white,
  });
  css.global(".ui.secondary.button:hover, .ui.secondary.button:focus", {
    backgroundColor: `${colors.primaryLighter}DD`,
    color: `${colors.white} !important`,
  });
  css.global(".ui.progress .bar", {
    minWidth: 0,
  });
  css.global(
    ".ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before",
    {
      backgroundColor: `${colors.secondary} !important`,
    }
  );
  css.global(
    ".ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before",
    {
      backgroundColor: `${colors.secondaryDark} !important`,
    }
  );
  css.global(".ui.modal>.close", {
    top: spacing.m,
    right: spacing.m,
    color: `${colors.primary} !important`,
  });
  css.global(".ui.modal", {
    borderRadius: `${spacing.s} !important`,
    overflow: "hidden",
  });
}
