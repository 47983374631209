import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { StudyGoalModal } from "./StudyGoalModal";
import { getCurrentCourseUser } from "../../selectors/coursesUserSelectors";
import { selectStudyGoalByProps } from "../../selectors/studyGoalSelectors";

function StudyGoalModalContainer(props) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen, props.reset]);

  return (
    <StudyGoalModal
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
      {...props}
    />
  );
}

const mapStateToProps = (state) => {
  const coursesUser = getCurrentCourseUser(state);

  return {
    studyGoal: selectStudyGoalByProps(state, {
      coursesUserId: coursesUser?.id,
    }),
  };
};

export default connect(mapStateToProps)(StudyGoalModalContainer);
