import { css } from "glamor";
import React, { useMemo } from "react";
import {
  Header,
  Icon,
  Modal,
  ModalContent,
  ModalDescription,
} from "semantic-ui-react";
import { Button, colors, Link, spacing } from "../library";
import StudyGoalFormContainer from "./StudyGoalFormContainer";

const modalClass = css({
  padding: spacing.s,
  overflow: "visible !important",
  ".ui.active.modal": {
    width: 480,
  },
  "@media (max-width: 768px)": {
    ".ui.active.modal": {
      width: 350,
    },
  },
}).toString();

const titleStyle = { fontSize: "1.5rem" };
const descriptionStyle = { textAlign: "center" };
const descriptionTextStyle = { lineHeight: 1.4 };
const buttonContentStyle = { fontSize: "1rem", fontWeight: 500 };
const editActionStyle = { color: colors.greyShadow };

export const buttonContent = (content) => (
  <div style={buttonContentStyle}>{content}</div>
);

export function StudyGoalModal({ open, handleClose, handleOpen, studyGoal }) {
  const trigger = useMemo(
    () =>
      !!studyGoal ? (
        <Link style={editActionStyle} onClick={handleOpen}>
          <Icon name="setting" />
          Editar metas
        </Link>
      ) : (
        <Button
          size="large"
          primary
          inverted
          content={buttonContent("Criar meta de estudos")}
        />
      ),
    [!!studyGoal]
  );

  return (
    <Modal
      className={modalClass}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      trigger={trigger}
    >
      <ModalContent>
        <ModalDescription style={descriptionStyle}>
          <Header style={titleStyle}>Programe seus estudos</Header>
          <p style={descriptionTextStyle}>
            Configure seu plano de estudos e organize sua rotina para alcançar
            suas metas com eficiência.
          </p>
        </ModalDescription>
        <StudyGoalFormContainer handleClose={handleClose} />
      </ModalContent>
    </Modal>
  );
}
