import React from "react";
import { Page, spacing } from "../library";
import { CoursesData } from "../courses/Courses";
import BannersContainer from "../banners/BannersContainer";
import { css } from "glamor";
import ContinueContainer from "../continue/ContinueContainer";
import GreetingContainer from "../greeting/GreetingContainer";

const bannersWrapper = css({
  marginBottom: spacing.l,
});

export default function Home(props) {
  const { hasBanners, hasActiveEnrollment, user } = props;

  return (
    <Page title={<GreetingContainer />}>
      {hasBanners && (
        <div className={bannersWrapper}>
          <BannersContainer />
        </div>
      )}

      <ContinueContainer />

      <CoursesData justOwned={hasActiveEnrollment} {...props} />
    </Page>
  );
}
