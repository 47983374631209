import { createSelector } from "reselect";

const getUserFiles = (state) => state.entities.userFiles;

export const getAllUserFiles = createSelector(getUserFiles, (userFiles) =>
  Object.values(userFiles)
);

export const getYearRetrospectiveUserFiles = createSelector(
  getAllUserFiles,
  (userFiles) => userFiles.filter(({ type }) => type == "year_retrospective_24")
);
