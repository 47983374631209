import React from "react";
import { css } from "glamor";
import { AiFillCaretLeft } from "react-icons/ai";
import { FaChalkboardTeacher } from "react-icons/fa";
import { GiGraduateCap } from "react-icons/gi";
import { RiFileMarkLine, RiTimeFill } from "react-icons/ri";
import { TbCertificate } from "react-icons/tb";
import { makeIsMobile } from "../../utils/useScreenWidth";
import zeroPad from "../../utils/zeroPad";
import CourseContentsContainer from "../courseContents/CourseContentsContainer";
import CourseBannerContainer from "./CourseBannerContainer";
import LessonListItemContainer from "../courseContents/LessonListItemContainer";
import LessonSearchFormContainer from "../lessons/LessonSearchFormContainer";
import CourseContinueLessonContainer from "./CourseContinueLessonContainer";
import {
  Button,
  Icon,
  Link,
  Page,
  Spinner,
  colors,
  spacing,
  styles,
} from "../library";
import CourseProgress from "./CourseProgress";
import { BiBookmark, BiDownload, BiFile } from "react-icons/bi";
import { HiOutlineClipboardList } from "react-icons/hi";
import StudyGoalContainer from "../studyGoal/StudyGoalContainer";

const content = css({
  display: "flex",
  justifyContent: "center",
  gap: spacing.l,
  flexWrap: "wrap",
});

const titleWrapper = css({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  gap: spacing.s,
  marginBottom: spacing.s,
});

const levelTitle = css({
  display: "flex",
  gap: spacing.s,
  alignItems: "center",
  justifyContent: "space-between",
  color: colors.greyShadow,
});

const title = css({
  color: colors.greyShadow,
  fontWeight: "normal",
});

const cardBase = {
  ...styles.cardShadow,
  backgroundColor: colors.white,
  borderRadius: spacing.xs,
};

const cardClass = css(styles.cardShadow, {
  marginTop: spacing.m,
  backgroundColor: colors.white,
  borderRadius: spacing.xs,
  minWidth: 200,
});

const cardContent = {
  padding: spacing.m,
};

const searchWrapper = {
  minWidth: 310,
};

const infoItem = css({
  display: "flex",
  gap: spacing.m,
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: spacing.xs,
  borderBottom: `1px solid ${colors.grey}`,
  padding: `0 ${spacing.m} ${spacing.xs} ${spacing.m}`,
  minHeight: 45,
});

const iconWrapper = css({
  display: "flex",
  gap: spacing.s,
  alignItems: "center",
});

const blackText = {
  color: colors.greyShadow,
};

const levelList = {
  flex: 1,
};

const searchTerm = { color: colors.greyDark, fontWeight: "normal" };
const emptyStyle = {
  paddingTop: spacing.m,
  color: colors.grey,
};

const back = css({
  color: colors.primaryShadow,
  display: "flex",
  alignItems: "center",
  gap: spacing.xxs,
  fontSize: 16,
  ":hover": {
    color: colors.primary,
    cursor: "pointer",
  },
});

const actionsWrapper = {
  display: "flex",
  flexDirection: "column",
  gap: spacing.m,
  textAlign: "center",
  marginTop: spacing.m,
};

const shortcutClass = css({
  display: "flex",
  paddingLeft: spacing.m,
  alignItems: "center",
  gap: spacing.xxs,
  fontSize: 16,
  cursor: "pointer",
  lineHeight: 1.2,
  ":hover": {
    color: colors.primaryLight,
  },
});

const marginBottom = { marginBottom: spacing.m };

const leftColumnClass = css({
  minWidth: 290,
  "@media(max-width: 575px)": {
    flex: 1,
  },
});
const topContentClass = css({
  display: "flex",
  flexDirection: "column",
  gap: spacing.m,
  marginBottom: spacing.l,
});

const infoNumber = (number) => (number < 10 ? "0" + number : number);

export default function Course(props) {
  const {
    course,
    lesson,
    levelCount,
    searchParam,
    lessons = [],
    canAccessCourse,
    courseUser,
    hasFiles,
    loading,
  } = props;
  const isMobile = makeIsMobile();

  return loading ? (
    <Page>
      <Spinner wrapped text="Carregando informações do curso..." />
    </Page>
  ) : (
    <Page title={course?.name}>
      {!isMobile && (
        <div className={topContentClass}>
          <CourseBannerContainer />
          <CourseContinueLessonContainer />
          <StudyGoalContainer />
        </div>
      )}

      <div className={content}>
        <div className={leftColumnClass}>
          <Link to="/" className={back} component="div">
            <AiFillCaretLeft size={18} /> VOLTAR
          </Link>

          {isMobile && (
            <div className={topContentClass}>
              <CourseBannerContainer />
              <CourseContinueLessonContainer />
              <StudyGoalContainer />
            </div>
          )}

          <div className={cardClass}>
            <div style={cardContent}>
              <div className={css(marginBottom, { marginTop: spacing.s })}>
                <CourseProgress
                  separator="/"
                  progress={course?.meta?.progress}
                />
              </div>
              <div className={infoItem}>
                <div className={iconWrapper}>
                  <FaChalkboardTeacher size={22} />
                  Aulas
                </div>
                <h1 style={blackText}>
                  {infoNumber(course?.meta.lessonsCount)}
                </h1>
              </div>
              <div className={infoItem}>
                <div className={iconWrapper}>
                  <RiFileMarkLine size={22} />
                  Módulos
                </div>
                <h1 style={blackText}>{zeroPad(levelCount, 2)}</h1>
              </div>
              {course.workload && (
                <div className={infoItem}>
                  <div className={iconWrapper}>
                    <RiTimeFill size={22} />
                    Duração
                  </div>
                  <h1 style={blackText}>{course.workload}</h1>
                </div>
              )}

              <div style={actionsWrapper}>
                {course?.meta?.progress >= 1 && (
                  <Link
                    href={course?.certificateUrl}
                    target="_blank"
                    className={shortcutClass}
                  >
                    <TbCertificate size={15} /> Emitir Certificado
                  </Link>
                )}

                {canAccessCourse && (
                  <>
                    <Link
                      to={`/courses/${course.id}/annotations`}
                      className={shortcutClass}
                    >
                      <BiBookmark size={18} />
                      Minhas Anotações
                    </Link>
                    {course?.mocksEnabled && (
                      <Link to={`/mocks/new`} className={shortcutClass}>
                        <HiOutlineClipboardList size={18} />
                        Simulados
                      </Link>
                    )}

                    {course?.workbookUrl && (
                      <Link
                        href={course.workbookUrl}
                        target="_blank"
                        className={shortcutClass}
                      >
                        <BiFile size={18} />
                        Download da Apostila
                      </Link>
                    )}

                    {hasFiles && (
                      <Link
                        to={`/courses/${course?.id}/downloads`}
                        target="_blank"
                        className={shortcutClass}
                      >
                        <BiDownload size={18} />
                        Downloads
                      </Link>
                    )}
                  </>
                )}

                <Link
                  to={`/courses/${course.id}/lessons/${lesson?.id}`}
                  component={Button}
                  disabled={!canAccessCourse || courseUser?.paused || !lesson}
                  secondary
                  circular
                  fluid
                >
                  <Icon
                    name={
                      canAccessCourse && !courseUser?.paused ? "play" : "lock"
                    }
                  />
                  {courseUser?.paused ? "TRANCADO" : "CONTINUAR"}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div style={levelList}>
          <div className={titleWrapper}>
            <div className={levelTitle}>
              <GiGraduateCap size={32} />
              <h2 className={title}>Módulos</h2>
            </div>
            <div style={searchWrapper}>
              <LessonSearchFormContainer />
            </div>
          </div>
          {!!searchParam ? (
            <div style={cardBase}>
              <div style={cardContent}>
                <h4 style={blackText}>
                  Exibindo resultados para{" "}
                  <span style={searchTerm}>{`"${searchParam}"`}</span>
                </h4>
                {!lessons?.length && (
                  <p style={emptyStyle}>Sem resultados para sua busca</p>
                )}

                {lessons?.map((lesson, index) => (
                  <LessonListItemContainer key={index} lesson={lesson} />
                ))}
              </div>
            </div>
          ) : (
            <CourseContentsContainer />
          )}
        </div>
      </div>
    </Page>
  );
}
