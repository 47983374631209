import React from "react";
import { Field } from "redux-form";
import buildFormField from "./buildFormField";
import TimeInput from "./TimeInput";
import memoize from "fast-memoize";

const FormField = buildFormField(TimeInput, (input, meta, rest) => {
  return {
    ...input,
    ...rest,
    invalid: `${meta.touched && (meta.invalid || rest.invalid)}`,
  };
});

const buildValidator = memoize((required, other) => {
  return [(value) => (required && !value ? "required" : undefined)].concat(
    other || []
  );
});

export default function TimeField(props) {
  return (
    <Field
      {...props}
      component={FormField}
      validate={buildValidator(props.required, props.validate)}
    />
  );
}
