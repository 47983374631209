import { css } from "glamor";
import React from "react";
import { colors, Icon, Progress, spacing } from "../library";
import { uiColors } from "../library/theme";
import StudyGoalModalContainer from "./StudyGoalModalContainer";
import WeekdayStudyGoalsContainer from "./WeekdayStudyGoalsContainer";
import { useGetStudyGoalCompletionDate } from "./useGetStudyGoalCompletionDate";

const containerClass = css({
  border: `1px solid ${colors.greyClear}`,
  textAlign: "center",
  borderRadius: "8px",
  boxShadow: `0px 3px 3px ${uiColors.border}`,
  padding: spacing.s,
  "@media (max-width: 768px)": {
    maxWidth: "calc(100vw - 64px)",
  },
});

const studyGoalInfoContainer = css({
  margin: spacing.s,
  "@media (max-width: 576px)": {
    margin: 0,
  },
});

const studyGoalHeaderClass = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: spacing.xs,
});

const studyGoalContentClass = css({
  display: "flex",
  alignItems: "center",
  gap: spacing.m,
  "@media (max-width: 1230px)": {
    marginTop: spacing.m,
    flexDirection: "column",
  },
});

const studyGoalProgressContainer = css({
  textAlign: "left",
  "@media (max-width: 1230px)": {
    width: "100%",
  },
  "@media (min-width: 1300px)": {
    flex: 1,
  },
});

const studyGoalProgressClass = css({
  margin: `${spacing.xxs} 0 !important`,
  ".ui.progress .bar": { background: colors.primary },
}).toString();

const titleStyle = {
  marginBottom: 0,
  fontWeight: "bold",
};

const descriptionTextStyle = {
  margin: "0 auto",
  marginBottom: spacing.s,
  maxWidth: 500,
};

const currentWeekdayName = Intl.DateTimeFormat("pt-BR", { weekday: "long" })
  .format(new Date())
  .replace("-feira", "");

const currentWeekdayNameStyle = {
  textTransform: "capitalize",
};

export default function StudyGoal({
  studyGoal,
  studyGoalProgress,
  totalStudiedTimeSeconds,
  course,
}) {
  const totalStudiedTimeMinutes = parseInt(totalStudiedTimeSeconds / 60);
  const studyDurationMinutes = parseInt(
    studyGoal?.dayStudyDurationSeconds / 60
  );
  const completionDate = useGetStudyGoalCompletionDate();
  const courseProgress = course.meta.progress;

  return (
    <div className={containerClass}>
      {studyGoal ? (
        <div className={studyGoalInfoContainer}>
          <div className={studyGoalHeaderClass}>
            <h4 style={titleStyle}>Sequência da semana</h4>
            <StudyGoalModalContainer />
          </div>
          <div className={studyGoalContentClass}>
            <WeekdayStudyGoalsContainer />

            <div className={studyGoalProgressContainer}>
              <p style={currentWeekdayNameStyle}>
                <Icon name="calendar outline" />
                {currentWeekdayName}
              </p>
              <Progress
                className={studyGoalProgressClass}
                size="tiny"
                percent={studyGoalProgress}
              />
              <p>
                {totalStudiedTimeMinutes} minutos assistidos /{" "}
                {studyDurationMinutes} minutos necessários -{" "}
                {courseProgress >= 1
                  ? "Curso concluído."
                  : `Previsão de conclusão em ${Intl.DateTimeFormat(
                      "pt-BR"
                    ).format(completionDate)}`}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <h4 style={titleStyle}>Sequência da semana</h4>
          <p style={descriptionTextStyle}>
            Você ainda não tem uma meta de estudo definida. Que tal começar
            agora e dar o primeiro passo rumo ao seu objetivo?
          </p>

          <StudyGoalModalContainer />
        </>
      )}
    </div>
  );
}
