import { css } from "glamor";
import { useCallback, useMemo } from "react";
import { colors } from "../library";

export const weekdayOptionClass = {
  width: 50,
  height: 50,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: colors.primary,
  fontSize: 18,
  fontWeight: 500,
  border: `1px solid ${colors.greyLight}`,
  borderRadius: "10px",
  cursor: "pointer",
  userSelect: "none",
  "@media (max-width: 768px)": {
    width: 35,
    height: 35,
  },
};

const selectedWeekdayStyle = {
  border: `1px solid ${colors.primary}`,
  backgroundColor: "rgba(0, 158, 222, 0.2)",
};

const setWeekdayOptionClass = (isSelected) =>
  css(weekdayOptionClass, isSelected && selectedWeekdayStyle);

export default function useWeekdayOption(weekday, fields) {
  const weekdayIndex = useMemo(
    () => fields?.getAll()?.findIndex((field) => field.weekday === weekday),
    [fields, weekday]
  );

  const isWeekdaySelected = weekdayIndex !== -1;

  const handleSelectWeekday = useCallback(() => {
    if (isWeekdaySelected) {
      fields.remove(weekdayIndex);
    } else {
      fields.push({ weekday });
    }
  }, [fields, isWeekdaySelected, weekdayIndex]);

  const optionClass = useMemo(
    () => setWeekdayOptionClass(isWeekdaySelected),
    [isWeekdaySelected]
  );

  return { optionClass, handleSelectWeekday };
}
