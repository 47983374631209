import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { getYearRetrospectiveUserFiles } from "../../selectors/userFileSelectors";
import { generateUserRetrospective } from "../../processes/userProcesses";
import {
  fetchUserFiles,
  userFileFetched,
} from "../../processes/userFilesProcesses";
import Retrospective from "./Retrospective";
import { ActionCableConsumer } from "react-actioncable-provider";
import { getCurrentUser } from "../../selectors/userSelectors";

function RetrospectiveContainer(props) {
  const { dispatch, user, yearRetrospectives } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserFiles(dispatch).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!loading && !yearRetrospectives.length && user) {
      generateUserRetrospective(dispatch, user.id);
    }
  }, [user, yearRetrospectives, loading]);

  const onReceived = useCallback(
    (payload) => {
      userFileFetched(dispatch, payload?.data);
    },
    [dispatch]
  );

  return (
    <ActionCableConsumer
      channel={{ channel: "UserFilesChannel" }}
      onReceived={onReceived}
    >
      <Retrospective {...props} />
    </ActionCableConsumer>
  );
}

function mapStateToProps(state) {
  return {
    user: getCurrentUser(state),
    yearRetrospectives: getYearRetrospectiveUserFiles(state),
  };
}

export default connect(mapStateToProps)(RetrospectiveContainer);
