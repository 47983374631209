import React from "react";
import useWeekdayOption from "./useWeekdayOption";
import { spacing } from "../library";
import FieldMessage from "../library/forms/FieldMessage";

const containerStyle = { marginBottom: spacing.s };

const weekdayOptionsContainer = {
  display: "flex",
  flexWrap: "nowrap",
  justifyContent: "space-between",
  marginBottom: spacing.xxs,
};

const errorMessageStyle = { fontSize: 12 };

export function WeekdayStudyGoalFields({ fields, meta }) {
  const {
    handleSelectWeekday: handleSelectSunday,
    optionClass: sundayOptionClass,
  } = useWeekdayOption("sunday", fields);

  const {
    handleSelectWeekday: handleSelectMonday,
    optionClass: mondayOptionClass,
  } = useWeekdayOption("monday", fields);

  const {
    handleSelectWeekday: handleSelectTuesday,
    optionClass: tuesdayOptionClass,
  } = useWeekdayOption("tuesday", fields);

  const {
    handleSelectWeekday: handleSelectWednesday,
    optionClass: wednesdayOptionClass,
  } = useWeekdayOption("wednesday", fields);

  const {
    handleSelectWeekday: handleSelectThursday,
    optionClass: thursdayOptionClass,
  } = useWeekdayOption("thursday", fields);

  const {
    handleSelectWeekday: handleSelectFriday,
    optionClass: fridayOptionClass,
  } = useWeekdayOption("friday", fields);

  const {
    handleSelectWeekday: handleSelectSaturday,
    optionClass: saturdayOptionClass,
  } = useWeekdayOption("saturday", fields);

  return (
    <div style={containerStyle}>
      <div style={weekdayOptionsContainer}>
        <div className={sundayOptionClass} onClick={handleSelectSunday}>
          D
        </div>
        <div className={mondayOptionClass} onClick={handleSelectMonday}>
          S
        </div>
        <div className={tuesdayOptionClass} onClick={handleSelectTuesday}>
          T
        </div>
        <div className={wednesdayOptionClass} onClick={handleSelectWednesday}>
          Q
        </div>
        <div className={thursdayOptionClass} onClick={handleSelectThursday}>
          Q
        </div>
        <div className={fridayOptionClass} onClick={handleSelectFriday}>
          S
        </div>
        <div className={saturdayOptionClass} onClick={handleSelectSaturday}>
          S
        </div>
      </div>

      {meta.error && (
        <FieldMessage style={errorMessageStyle} message={`* ${meta.error}`} />
      )}
    </div>
  );
}
