import { css } from "glamor";
import React, { useMemo } from "react";
import Hint from "../Hint";
import { spacing } from "../theme";
import FieldBlock from "./FieldBlock";
import FieldMessage from "./FieldMessage";
import Label from "./Label";

const layout = {
  message: {
    textAlign: "left",
    fontSize: 12,
    marginTop: spacing.xs,
  },
  label: {
    marginBottom: spacing.xxs,
    display: "block",
  },
  errorStyle: {
    "> * > input, > input, > textarea": {
      border: `1px solid red !important`,
    },
  },
  emptyMessage: {
    display: "none",
  },
};

const errorWrapper = css({
  position: "relative",
  display: "inline-block",
  width: "100%",
});

const defaultMessages = {
  required: "* Campo obrigatório",
  email: "* E-mail inválido",
  emailConfirmation: "* E-mail não coincide",
  minSize: "* Muito curto",
  maxSize: "* Muito longo",
  minWords: "* Número de palavras insuficientes",
  invalid: "* Inválido",
};

const defaultShortMessages = {
  required: "* Obrigatório",
  email: "* Ínvalido",
  emailConfirmation: "* Não coincide",
  minSize: "* Muito curto",
  maxSize: "* Muito longo",
  minWords: "* Palavras insuficientes",
  invalid: "* Inválido",
};

function errorMessage(errorType, customMessages, short = false) {
  return (
    (customMessages && customMessages[errorType]) ||
    (short ? defaultShortMessages : defaultMessages)[errorType] ||
    errorType
  );
}

export default (InputComponent, selectProps) => (props) => {
  const {
    input = {},
    meta = {},
    style,
    label,
    labelClass,
    labelLayout,
    innerLabel,
    hint,
    required,
    validationMessages,
    shortMessages,
    noMessages,
    message,
    minSize, // eslint-disable-line no-unused-vars
    ...rest
  } = props;
  const id = input.id || input.name;
  const error = meta.touched ? meta.error : undefined;
  const errorMsg = error
    ? errorMessage(error, validationMessages, shortMessages)
    : undefined;
  const inputWrapperClass = error && css(layout.errorStyle);
  const hasMessage = !!message || !!error;

  return (
    <FieldBlock style={{ ...style, width: rest.width }}>
      {!!label && (
        <Label
          className={labelClass}
          style={{ ...layout.label, ...labelLayout }}
          required={required}
          htmlFor={id}
          disabled={props.disabled}
        >
          {label}
        </Label>
      )}
      <div className={inputWrapperClass}>
        <InputComponent
          {...selectProps({ ...input, id: id }, meta, rest)}
          error={!!error}
          innerlabel={innerLabel}
        />
      </div>
      <span
        className={css(errorWrapper, !hasMessage && layout.emptyMessage, {
          width: rest.width,
        })}
      >
        {!noMessages && (
          <>
            {errorMsg && (
              <FieldMessage style={layout.message} message={errorMsg} />
            )}
            {message && (
              <FieldMessage
                type="success"
                style={layout.message}
                message={message}
              />
            )}
          </>
        )}
        {hint && <Hint>{hint}</Hint>}
      </span>
    </FieldBlock>
  );
};
