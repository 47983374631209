export const generateTimeIntervalOptions = (hours, minutesInterval = 15) => {
  const intervals = [];
  for (
    let minutes = minutesInterval;
    minutes <= hours * 60;
    minutes += minutesInterval
  ) {
    const hours = Math.floor(minutes / 60)
      .toString()
      .padStart(2, "0");
    const mins = (minutes % 60).toString().padStart(2, "0");
    intervals.push({ text: `${hours}:${mins}`, value: minutes * 60 });
  }
  return intervals;
};
