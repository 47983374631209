import { apiGet, apiPost, apiPut } from "./helpers/api";
import { normalize, schema } from "normalizr";

const studyGoal = new schema.Entity("studyGoals", {
  weekdayStudyGoalsAtrtibutes: [new schema.Entity("weekdayStudyGoals")],
});

export const fetchStudyGoal = (dispatch, studyGoalId) => {
  return apiGet(`/api/v1/study_goals/${studyGoalId}`).then((response) => {
    dispatch({
      type: "STUDY_GOAL_FETCHED",
      ...normalize(response.body.data, studyGoal),
    });
  });
};

export const fetchStudyGoals = (dispatch) => {
  dispatch({ type: "STUDY_GOALS_FETCH_REQUESTED" });
  return apiGet("/api/v1/study_goals").then((response) => {
    dispatch({
      type: "STUDY_GOALS_FETCHED",
      ...normalize(response.body.data, new schema.Array(studyGoal)),
    });
  });
};

export const createStudyGoal = (values, dispatch) => {
  dispatch({ type: "STUDY_GOAL_SUBMITTED" });
  return apiPost("/api/v1/study_goals/")
    .send(values)
    .then((response) => {
      dispatch({
        type: "STUDY_GOAL_CREATED",
        ...normalize(response.body.data, studyGoal),
      });
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response.body.errors });
    });
};

export const upsertDayStudyGoalsLesson = (
  dispatch,
  studyGoalId,
  lessonId,
  studiedTimeSeconds
) => {
  return apiPut(`/api/v1/day_study_goals_lessons`)
    .send({ studyGoalId, lessonId, studiedTimeSeconds })
    .then(() => {})
    .catch(
      (err) =>
        new Error(
          `Error on create or update the day study goals lesson: ${err.response.body.errors}`
        )
    );
};
