import { useMemo } from "react";
import { useSelector } from "react-redux";
import { currentCourse } from "../../selectors/courseSelectors";
import { getCurrentCourseUser } from "../../selectors/coursesUserSelectors";
import { selectStudyGoalByProps } from "../../selectors/studyGoalSelectors";
import { findDateByTargetWeekdays } from "../../utils/findDateByTargetWeekdays";

export function useGetStudyGoalCompletionDate() {
  const coursesUser = useSelector((state) => getCurrentCourseUser(state));
  const course = useSelector((state) => currentCourse(state));
  const studyGoal = useSelector((state) =>
    selectStudyGoalByProps(state, { coursesUserId: coursesUser?.id })
  );

  const remainingCourseTime = useMemo(() => {
    const workload = parseInt(course.workloadValue);
    const courseProgress = course.meta.progress;

    return workload * (1 - courseProgress);
  }, [course]);

  const remainingDaysToStudyAmount = useMemo(
    () =>
      studyGoal?.dayStudyDurationSeconds
        ? Math.ceil(remainingCourseTime / studyGoal?.dayStudyDurationSeconds)
        : 0,
    [remainingCourseTime, studyGoal?.dayStudyDurationSeconds]
  );

  const chosenWeekdays = useMemo(
    () =>
      (studyGoal?.weekdayStudyGoalsAttributes || []).map(
        (weekdayStudyGoal) => weekdayStudyGoal.weekday
      ),
    [studyGoal]
  );

  const completionDate = useMemo(
    () => findDateByTargetWeekdays(chosenWeekdays, remainingDaysToStudyAmount),
    [chosenWeekdays, remainingDaysToStudyAmount]
  );

  return completionDate;
}
