import { css } from "glamor";
import React from "react";
import { FieldArray } from "redux-form";
import { generateTimeIntervalOptions } from "../../utils/generateTimeIntervalOptions";
import { Button, colors, spacing } from "../library";
import { DropDownField, Form } from "../library/forms";
import TimeField from "../library/forms/TimeField";
import { WeekdayStudyGoalFields } from "./WeekdayStudyGoalFields";
import { buttonContent } from "./StudyGoalModal";

const buttonsContainer = css({
  display: "flex",
  justifyContent: "end",
  background: "none !important",
  border: "none !important",
  gap: spacing.m,
  marginTop: spacing.l,
  "> button.ui.button": {
    margin: 0,
  },
  "@media (max-width: 768px)": {
    justifyContent: "space-between",
    "> button > div": {
      fontSize: "0.9rem !important",
    },
  },
});

const timeFieldsContainer = css({
  display: "flex",
  justifyContent: "space-between",
  "@media (max-width: 768px)": {
    flexDirection: "column",
    gap: spacing.s,
  },
});

const labelStyle = { color: colors.black, fontWeight: 500 };

const fieldLabelClass = {
  ":after": { content: "" },
  fontSize: 13,
  ...labelStyle,
};

const dayStudyDurationFieldClass = css({
  "> i.icon": {
    color: colors.grey,
  },
});

const dayStudyDurationFieldStyle = { marginTop: 0 };
const dayStudyDurationOptions = generateTimeIntervalOptions(8);

const validateWeekdayStudyGoalFields = (chosenOptions) => {
  if (chosenOptions?.length <= 0) return "Escolha pelo menos um dia da semana";
};

export function StudyGoalForm({
  handleSubmit,
  handleFormResetAndClose,
  submitting,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <p style={labelStyle}>Quais dias da semana você quer estudar?</p>
      <FieldArray
        name="weekdayStudyGoalsAttributes"
        component={WeekdayStudyGoalFields}
        validate={validateWeekdayStudyGoalFields}
      />
      <div className={timeFieldsContainer}>
        <TimeField
          name="dayStudyStartsAt"
          labelClass={fieldLabelClass}
          label="Qual horário você deseja estudar?"
          required
          fluid
        />
        <DropDownField
          style={dayStudyDurationFieldStyle}
          className={dayStudyDurationFieldClass}
          name="dayStudyDurationSeconds"
          labelClass={fieldLabelClass}
          label="Qual tempo de estudo?"
          options={dayStudyDurationOptions}
          required
          fluid
        />
      </div>
      <div className={buttonsContainer}>
        <Button
          size="large"
          primary
          inverted
          onClick={handleFormResetAndClose}
          content={buttonContent("Cancelar")}
        />
        <Button
          size="large"
          primary
          content={buttonContent("Planejar estudos")}
          disabled={submitting}
          loading={submitting}
        />
      </div>
    </Form>
  );
}
