import React from "react";
import { Input } from "semantic-ui-react";
import { css } from "glamor";
import { colors } from "../theme";

const inputClass = css({
  "> input": {
    border: `1px solid ${colors.grey} !important`,
    borderRadius: "10px",
  },
  "> input[type='time']::-webkit-calendar-picker-indicator": {
    display: "none",
    "-webkit-appearance": "none",
  },
  "> i": {
    color: colors.greyDark,
  },
});

export default function TimeInput(props) {
  return (
    <Input
      className={inputClass.toString()}
      type="time"
      icon="clock outline"
      {...props}
    />
  );
}
