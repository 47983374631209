import { apiPost } from "./helpers/api";
import moment from "moment";

const LAST_SYNC_KEY = "lastChatleadSync";

export const syncChatleadContact = () => {
  const now = moment();
  const lastSync = localStorage.getItem(LAST_SYNC_KEY);

  if (!lastSync || now.diff(moment(parseInt(lastSync, 10)), "days") >= 1) {
    return apiPost("/api/v1/sync_chatlead_contact")
      .then(() => {
        localStorage.setItem(LAST_SYNC_KEY, now.valueOf().toString());
      })
  }
};
