const getStudyGoals = (action) => action.entities.studyGoals;

export default function studyGoalsReducer(state = {}, action) {
  switch (action.type) {
    case "STUDY_GOAL_FETCHED":
    case "STUDY_GOALS_FETCHED":
    case "STUDY_GOAL_CREATED":
      return { ...state, ...getStudyGoals(action) };
    default:
      return state;
  }
}
