import React from "react";
import Page from "../library/Page";
import { css } from "glamor";
import Link from "../library/Link";
import Spinner from "../library/Spinner";
import Button from "../library/Button";
import { spacing, colors } from "../library/theme";

const RETROSPECTIVE_SHARE_BLUE = "#354CFC";

const yearRetrospectiveWrapperClass = css({
  position: "relative",
  boxSizing: "border-box",
  width: "100%",
  borderRadius: spacing.m,
  boxShadow: "10px 0px 24px 0px #00000040",
  marginBottom: spacing.xl,
  ":hover": {
    cursor: "pointer",
  },
});
const listClass = css({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(260px, 1fr))",
  gap: spacing.m,
  margin: "0 auto",
  width: "100%",
});
const aspectRatioClass = css({
  height: 0,
  width: "100%",
  paddingBottom: "177%",
  position: "relative",
});
const bgClass = css(aspectRatioClass, {
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
});
const downloadStyle = {
  backgroundColor: RETROSPECTIVE_SHARE_BLUE,
  color: colors.white,
  position: "absolute",
  bottom: "-45px",
  left: 0,
  right: 0,
  width: "100%",
  textAlign: "center",
  ":hover": {
    cursor: "pointer",
  },
};

export default function Retrospective(props) {
  return (
    <Page title="Retrospectiva 2024">
      {props.yearRetrospectives.length > 0 ? (
        <div className={listClass}>
          {props.yearRetrospectives.slice(0, 6).map((yearRetrospective) => (
            <Link
              className={yearRetrospectiveWrapperClass}
              target="_blank"
              href={yearRetrospective.fileUrl}
            >
              <div
                className={css(bgClass, {
                  backgroundImage: `url(${yearRetrospective.fileUrl})`,
                })}
              />
              <Link
                component={Button}
                style={downloadStyle}
                href={yearRetrospective.fileUrl}
                download="Retrospectiva2024"
                target="_blank"
              >
                Compartilhe em suas redes
              </Link>
            </Link>
          ))}
        </div>
      ) : (
        <Spinner wrapped text="Carregando..." />
      )}
    </Page>
  );
}
