import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { StudyGoalForm } from "./StudyGoalForm";
import { getCurrentCourseUser } from "../../selectors/coursesUserSelectors";
import { selectStudyGoalByProps } from "../../selectors/studyGoalSelectors";
import { createStudyGoal } from "../../processes/studyGoalProcesses";
import { getCurrentUserId } from "../../selectors/userSelectors";

function StudyGoalFormContainer(props) {
  const { handleClose, reset, submitSucceeded } = props;

  const handleFormResetAndClose = useCallback(() => {
    reset();
    handleClose();
  }, [handleClose, reset]);

  useEffect(() => {
    if (submitSucceeded) {
      handleFormResetAndClose();
    }
  }, [handleFormResetAndClose, submitSucceeded]);

  return (
    <StudyGoalForm
      handleFormResetAndClose={handleFormResetAndClose}
      {...props}
    />
  );
}

const mapStateToProps = (state) => {
  const currentUserId = getCurrentUserId(state);
  const coursesUser = getCurrentCourseUser(state);
  const studyGoal = selectStudyGoalByProps(state, {
    coursesUserId: coursesUser?.id,
  });
  const initialWeekdayStudyGoalsAttributes =
    studyGoal?.weekdayStudyGoalsAttributes?.map((weekday) => {
      const { id, ...rest } = weekday;
      return rest;
    }) || [];

  return {
    initialValues: {
      dayStudyStartsAt: studyGoal?.dayStudyStartsAt,
      dayStudyDurationSeconds: studyGoal?.dayStudyDurationSeconds || 900,
      weekdayStudyGoalsAttributes: initialWeekdayStudyGoalsAttributes,
      userId: currentUserId,
      coursesUserId: coursesUser?.id,
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: "studyGoalForm",
    onSubmit: createStudyGoal,
  })(StudyGoalFormContainer)
);
